<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class class="modal-body">
    <div class="col-md-12 mb-2">
      <!-- DETAILS SUR LE DEVIS  -->
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width"> DETAILS SUR L'INTERVENTION </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-3">
            <label>Intervention <span class="asterix">*</span></label>
            <app-entity-finder [class]="'Construction'" [groups]="['construction']"
              [required]="true" [selected]="currentConstruction"
              [disabled]="this.edit" (uuid)="setConstructionUuid($event)">
            </app-entity-finder>
          </div>
          <div class="invalid-feedback" *ngIf="submit && f.construction.errors">
            <div *ngIf="f.construction.errors.required">{{required.novide}}</div>
          </div>
          <div class="col-md-3">
            <label >Bien </label>
            <input type="text" class="form-control p-2 text-warning" readonly [value]="construction?.rental ? construction?.house?.searchableTitle : construction?.rental?.libelle">
          </div>
          <div class="col-md-3">
            <label for="montant">Montant </label>
            <input type="text" class="form-control p-2 text-warning " readonly [value]="construction ? construction?.budget: ''">
          </div>
          <div class="col-md-3">
            <label for="etat">Etat<span class="asterix">*</span></label>
            <select formControlName="etat" class="form-control" id="etat">
              <option [ngValue]="'EN COURS'">EN COURS </option>
              <option [ngValue]="'TERMINER'">TERMINER</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- VALIDATION DES DEVIS  -->
    <div class="col-sm-12" formArrayName="options" *ngIf="f.construction.value">
      <div *ngFor="let item of option.controls; let c = index" [formGroupName]="c">
        <div class="row">
          <span class="badge badge-warning my-2 f-14 width">{{ item.get('devis').value }}</span>
        </div>
          <div  class="row">
            <table class="table table-sm table-striped table-bordered nowrap table-hover">
              <thead class="thead-light">
                <tr>
                  <th>Libéllé</th>
                  <th>Statut</th>
                  <th>Total</th>
                  <th>Terminer</th>
                </tr>
              </thead>
              <tbody class="task-page" formArrayName="suboptions">
                <tr *ngFor="let subItem of subOption(c).controls; let i = index" [formGroupName]="i">
                  <td><input formControlName="libelle" type="text" class="form-control p-2 bold" [id]="'libelle' + i"></td>
                  <td><input formControlName="statut" type="text" class="form-control p-2 bold" [id]="'statut' + i"></td>
                  <td><input formControlName="montant" type="text" class="form-control p-2 bold" [id]="'montant' + i"></td>
                  <td>
                    <div class="form-group">
                      <div class="form-check">
                        <input formControlName="checked" class="form-check-input" type="checkbox" [id]="'checked' + i">
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
    
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
