<div class="row help-desk">
    <div class="col-md-12 mb-3">
        <app-card [hidHeader]="true">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1 d-flex flex-column justify-content-start">
                    <div>
                        <h4>Fissure des vitres de la fenetre de ma chambre </h4>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-outline-primary btn-sm mr-1"><i class="feather icon-edit-2"></i></button>
                    <button type="button" class="btn btn-outline-warning btn-sm mr-1"><i class="fas fa-comment"></i></button>
                    <button type="button" class="btn btn-outline-success btn-sm mr-1"><i class="feather icon-share-2"></i></button>
                    <button type="button" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash"></i></button>
                </div>
            </div>
            <div class="border border-2 rounded p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1 d-flex flex-column justify-content-start">
                        <h6> Status</h6>
                        <h6><span class="badge badge-warning">ATTENTE </span></h6>
                    </div>
                    <div>
                        <a href="javascript:void(0)" ngbTooltip="qualifier" class="text-primary"> <i class="feather icon-share-2 text-primary"></i> Partager avec l'exterieur </a>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="flex-fill">
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                    </div>
                    <div class="flex-fill">
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                    </div>
                    <div class="flex-fill">
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                        <p>
                            <span>Ticket ID</span>
                            <span># 4794-3809n</span>
                        </p>
                    </div>

                </div>
            </div>
        </app-card>
    </div>
</div>