import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl }from '@angular/forms';
import { Construction } from '@model/construction';
import { Production } from '@model/production';
import { Quote } from '@model/quote';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructionService } from '@service/construction/construction.service';
import { ProductionService } from '@service/production/production.service';
import { QuoteService } from '@service/quote/quote.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '@theme/utils/globals';
import { EmitterService } from '@service/emitter/emitter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-production-add',
  templateUrl: './production-add.component.html',
  styleUrls: ['./production-add.component.scss']
})
export class ProductionAddComponent implements OnInit {
  title: string = ""
  edit: boolean = false
  montantA: number
  form: FormGroup
  isHidden: boolean = false
  submit: boolean = false
  production: Production
  construction: Construction
  quote: Quote
  quotes: Quote[]
  currentConstruction?: any;
  global = {country: Globals.country, device: Globals.device}

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private quoteService: QuoteService,
    private productionService: ProductionService,
    private constructionService: ConstructionService,
    public toastr: ToastrService,
    private emitter: EmitterService
  ) {
    this.edit = this.productionService.edit
    this.production = this.productionService.getProduction()
    this.title = (!this.edit) ? "Ajouter une realisation" : "Modifier le realisation "+this.production?.construction?.nom
    this.newForm()
   }

  ngOnInit(): void {
    this.editForm()
  }
  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      etat: ['EN COURS', [Validators.required]],
      construction: [null, [Validators.required]],
      options: this.formBuild.array(this.itemOption()),
    });
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.productionService.getProduction() }
      this.setConstructionUuid(data.construction.uuid);
      this.construction = data?.construction
      this.f.etat.setValue( data?.construction?.etat)
      this.currentConstruction = {
        photoSrc: data?.construction?.photoSrc,
        title: data?.construction?.searchableTitle,
        detail: data?.construction?.telephone
      };
      this.quoteService.getList(this.f.construction.value, 'PRESTATION').subscribe((res: any) => {
        res?.forEach((item) => {
          const optionsArray: FormGroup[] = [];
          item.options.forEach((option) =>{
            optionsArray.push(
              this.formBuild.group({
                id: [option.id],
                checked: [{value: false, disabled: option?.evolution ? true : false}, [Validators.required]],
                libelle: [{value: option?.product?.libelle, disabled: true}, [Validators.required]],
                statut: [{value: option?.evolution ? 'TERMINER' : 'EN COURS', disabled: true}, [Validators.required]],
                montant: [{value: option?.prix, disabled: true}, [Validators.required]]
              })
            )
          })
          this.option.push(
            this.formBuild.group({
              uuid: [item.uuid],
              prestataire: [{value: item?.provider?.nom, disabled: true}, [Validators.required]],
              devis: [{value: item?.libelle, disabled: true}, [Validators.required]],
              suboptions: this.formBuild.array(optionsArray),
            })
          )
        });
      }, error => {}
      )
      this.form.patchValue(data)
    }
  }
  setConstructionUuid(uuid) {
    this.f.construction.setValue(uuid);
    if(!this.edit && uuid){
      this.constructionService.getSingle(uuid).subscribe((res: any) => { this.construction = res });
      this.loadQuote();
    }
    if(!uuid){
      this.f.construction.setValue(null);
      this.construction = null
      this.quotes = []
    }
  }

  loadQuote(){
    this.option.controls = []
    if(this.f.construction.value){
      this.quoteService.getList(this.f.construction.value, 'PRESTATION').subscribe((res: any) => {
        this.quotes = res;
        if(this.quotes && !this.edit){
          this.option.controls = this.itemOption()
        }
        }, error => {}
      );
    }
  }
  itemOption(): FormGroup[] {
    const arr: any[] = []
    if(this.quotes && this.quotes.length > 0){
      this.quotes.forEach((item) =>{
        const optionsArray: FormGroup[] = [];
        item.options.forEach((option) =>{
          optionsArray.push(
            this.formBuild.group({
              id: [option.id],
              checked: [{value: false, disabled: item?.evolution ? true : false}, [Validators.required]],
              libelle: [{value: option?.product?.libelle, disabled: true}, [Validators.required]],
              statut: [{value: option?.evolution ? 'TERMINER' : 'EN COURS', disabled: true}, [Validators.required]],
              montant: [{value: option?.prix, disabled: true}, [Validators.required]]
            })
          )
        })
        arr.push(
          this.formBuild.group({
            uuid: [item.uuid],
            prestataire: [{value: item?.provider?.nom, disabled: true}, [Validators.required]],
            devis: [{value: item?.libelle, disabled: true}, [Validators.required]],
            suboptions: this.formBuild.array(optionsArray),
          })
        )
      })
    }
    return arr;
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.productionService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (data?.uuid) {
            this.emitter.emit({action: 'PRODUCTION_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'PRODUCTION_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  get f() { return this.form.controls }
  get option() { return this.form.get('options') as FormArray; }
  subOption(empIndex:number) : FormArray {
    return this.option.at(empIndex).get("suboptions") as FormArray
  }
  
}
