
import { forkJoin, of } from 'rxjs';
import { Ticket } from '@model/ticket';
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";
import {environment} from '@env/environment';
import { Globals } from '@theme/utils/globals';
import {HttpClient} from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';


class FilterItem {
  key?: string;
  type?: string;
  libelle?: string;
  icon?: string;
  status?: boolean;
  color?: string;
  row?: any[];
  value?: string;
}

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit, AfterViewInit  {
  users: any[] = [];
  tenants: any[] = [];
  tickets: Ticket;
  owners: any[] = [];
  houses: any[] = [];
  rentals: any[] = [];
  categories: any[] = [];

  filterItems = []; // Votre liste complète des items
  filtersList = []; // Votre liste complète des items
  searchTerm: string = '';
  searchTermInput: string = '';
  originalItems: FilterItem[] =[
    {
      key: "reference",
      type: "text",
      libelle: "Reference",
      status: true,
      icon: "fas fa-hashtag",
      row: [],
      value: null
    },
    {
      key: "categorie",
      type: "textbox",
      libelle: "Catégorie",
      status: true,
      icon: "fas fa-ruler",
      row: this.categories,
      value: null
    },
    {
      key: "assinger",
      type: "textbox",
      libelle: "Assinger",
      status: true,
      icon: "fas fa-user",
      row: this.users,
      value: null
    },
    {
      key: "tenant",
      type: "textbox",
      libelle: "Locataire",
      status: true,
      icon: "fas fa-user",
      row: this.tenants,
      value: null
    },
    {
      key: "owner",
      type: "textbox",
      libelle: "Propriétaire",
      status: true,
      icon: "fas fa-user",
      row: this.owners,
      value: null
    },
    {
      key: "house",
      type: "textbox",
      libelle: "Bien",
      status: true,
      icon: "feather icon-home",
      row: this.houses,
      value: null
    },
    {
      key: "rental",
      type: "textbox",
      libelle: "Locative",
      status: true,
      icon: "feather icon-home",
      row: this.rentals,
      value: null
    },
    {
      key: "etat",
      type: "textbox",
      libelle: "Etat",
      status: true,
      icon: "fas fa-star",
      row: this.rentals,
      value: null
    },
    {
      key: "date",
      type: "date",
      libelle: "Date Creation",
      status: false,
      icon: "fas fa-clock",
      row: this.rentals,
      value: null
    },
    {
      key: "periode",
      type: "date-2",
      libelle: "Periode",
      status: false,
      icon: "fas fa-clock",
      row: this.rentals,
      value: null
    },
  ]

	public activeTab = "A FAIRE";
  public url = environment.serverUrl;
  public view = 'TABLE';
  public showComment = false;
  public loading = false;

  dtOptions: any = {};
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user

  events = [
    { id: '1', name: 'Alpha Corp', eventColor: '#ff5733' },
    { id: '2', name: 'Beta Ltd', eventColor: '#33ff57' },
    { id: '3', name: 'Gamma Inc', eventColor: '#3357ff' },
    { id: '4', name: 'Delta LLC', eventColor: '#ff33aa' },
    { id: '5', name: 'Epsilon SA', eventColor: '#ffaa33' },
    { id: '6', name: 'Zeta GmbH', eventColor: '#33aaff' },
    { id: '7', name: 'Eta Co', eventColor: '#aa33ff' },
    { id: '8', name: 'Theta Pvt Ltd', eventColor: '#aaff33' },
    { id: '9', name: 'Iota Ventures', eventColor: '#ff33aa' },
    { id: '10', name: 'Kappa Solutions', eventColor: '#33ffaa' },
  ];
  datas =  [
    { id: 1, startDate: '2024-08-01', endDate: '2024-08-07', name: 'ALPHA CORP : Project Launch', allDay: true, resourceId: '1', eventColor: '#ff5733' },
    { id: 2, startDate: '2024-08-02', endDate: '2024-08-08', name: 'BETA LTD : Team Meeting', allDay: true, resourceId: '2', eventColor: '#33ff57' },
    { id: 3, startDate: '2024-08-03', endDate: '2024-08-09', name: 'GAMMA INC : Product Demo', allDay: true, resourceId: '3', eventColor: '#3357ff' },
    { id: 4, startDate: '2024-08-04', endDate: '2024-08-10', name: 'DELTA LLC : Client Visit', allDay: true, resourceId: '4', eventColor: '#ff33aa' },
    { id: 5, startDate: '2024-08-05', endDate: '2024-08-11', name: 'EPSILON SA : Strategy Meeting', allDay: true, resourceId: '5', eventColor: '#ffaa33' },
    { id: 6, startDate: '2024-08-06', endDate: '2024-08-12', name: 'ZETA GMBH : Workshop', allDay: true, resourceId: '6', eventColor: '#33aaff' },
    { id: 7, startDate: '2024-08-07', endDate: '2024-08-13', name: 'ETA CO : Annual Review', allDay: true, resourceId: '7', eventColor: '#aa33ff' },
    { id: 8, startDate: '2024-08-08', endDate: '2024-08-14', name: 'THETA PVT LTD : Training', allDay: true, resourceId: '8', eventColor: '#aaff33' },
    { id: 9, startDate: '2024-08-09', endDate: '2024-08-15', name: 'IOTA VENTURES : Sales Conference', allDay: true, resourceId: '9', eventColor: '#ff33aa' },
    { id: 10, startDate: '2024-08-10', endDate: '2024-08-16', name: 'KAPPA SOLUTIONS : Product Launch', allDay: true, resourceId: '10', eventColor: '#33ffaa' },
  ];
  calendarOptions: any = {
    viewPreset: 'dayAndWeek',
    resources: this.events,
    events: this.datas
  };

  constructor(
    public router: Router,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.filterItems = this.originalItems; // Ou effectuer une copie si nécessaire
    this.filtersList = this.originalItems; // Ou effectuer une copie si nécessaire
  }

  ngAfterViewInit() {
    this.loadData();
  }

  edit() {

  }

  show() {
    // this.router.navigate(['/admin/ticket/show/' + row.uuid]);
    this.router.navigate(['/admin/ticket/show/1']);
  }

  comment() {
    this.showComment = !this.showComment;
  }
  qualifier() {

  }
  onTabs(type){
    this.activeTab = type
  }
  onView(view){
    this.view = view
  }

  filter() {
    this.filterItems = this.originalItems.filter(item =>
      item.libelle.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  clickCalandar(arg) {
    alert('date click! ' + arg.dateStr)
  }
  filterInput(item) {
    this.filterItems = this.originalItems.filter(item =>
      item.libelle.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
  addFilter(item){
    item.status = true
    this.filtersList.push(item)

    // Trouver l'index de l'élément dans filterItems
    const index = this.filterItems.findIndex(filterItem => filterItem === item);
    if (index > -1) {
        this.filterItems.splice(index, 1);
    }
  }
  removeFilter(item){
    item.status = false
    this.filterItems.push(item)

    // Trouver l'index de l'élément dans filterItems
    const index = this.filtersList.findIndex(filterItem => filterItem === item);
    if (index > -1) {
        this.filtersList.splice(index, 1);
    }
  }
  loadData() {
    forkJoin([
      this.loadUsers(),
      this.loadHouses(),
      this.loadTenants(),
      this.loadOwners(),
      this.loadRentals()
    ]).subscribe(results => {
      // qualifierer les résultats aux variables appropriées
      this.users = results[0];
      this.houses = results[1];
      this.tenants = results[2];
      this.owners = results[3];
      this.rentals = results[4];

      // Mettre à jour les éléments de filterItems qui dépendent de ces listes
      this.updateFilterItems();
    }, error => {
      console.error('Erreur lors du chargement des données :', error);
    });
  }
  updateFilterItems() {
    this.originalItems = [
      {
        key: "reference",
        type: "text",
        libelle: "Reference",
        status: true,
        icon: "fas fa-hashtag",
        row: [],
        value: null
      },
      {
        key: "categorie",
        type: "textbox",
        libelle: "Catégorie",
        status: true,
        icon: "fas fa-ruler",
        row: this.categories,
        value: null
      },
      {
        key: "assinger",
        type: "textbox",
        libelle: "Assinger",
        status: true,
        icon: "fas fa-user",
        row: this.users,
        value: null
      },
      {
        key: "tenant",
        type: "textbox",
        libelle: "Locataire",
        status: true,
        icon: "fas fa-user",
        row: this.tenants,
        value: null
      },
      {
        key: "owner",
        type: "textbox",
        libelle: "Propriétaire",
        status: true,
        icon: "fas fa-user",
        row: this.owners,
        value: null
      },
      {
        key: "house",
        type: "textbox",
        libelle: "Bien",
        status: true,
        icon: "feather icon-home",
        row: this.houses,
        value: null
      },
      {
        key: "rental",
        type: "textbox",
        libelle: "Locative",
        status: true,
        icon: "feather icon-home",
        row: this.rentals,
        value: null
      },
      {
        key: "etat",
        type: "textbox",
        libelle: "Etat",
        status: true,
        icon: "fas fa-star",
        row: this.rentals,
        value: null
      },
      {
        key: "date",
        type: "date",
        libelle: "Date Creation",
        status: false,
        icon: "fas fa-clock",
        row: this.rentals,
        value: null
      },
      {
        key: "periode",
        type: "date-2",
        libelle: "Periode",
        status: false,
        icon: "fas fa-clock",
        row: this.rentals,
        value: null
      },
    ];


    // Mettre à jour les filtres
    this.filterItems = [...this.originalItems];
    console.log(this.filterItems, this.originalItems)
  }
  loadUsers() {
    return this.http.get<any[]>(this.url + '/private/agency/user/filter').pipe(
      catchError(error => {
        console.error('Erreur lors du chargement des utilisateurs :', error);
        return of([]); // Retourne un tableau vide en cas d'erreur
      })
    );
  }

  loadHouses() {
    return this.http.get<any[]>(this.url + '/private/agency/house/filter').pipe(
      catchError(error => {
        console.error('Erreur lors du chargement des maisons :', error);
        return of([]); // Retourne un tableau vide en cas d'erreur
      })
    );
  }

  loadTenants() {
    return this.http.get<any[]>(this.url + '/private/agency/tenant/filter').pipe(
      catchError(error => {
        console.error('Erreur lors du chargement des locataires :', error);
        return of([]); // Retourne un tableau vide en cas d'erreur
      })
    );
  }
  loadOwners() {
    return this.http.get<any[]>(this.url + '/private/agency/owner/filter').pipe(
      catchError(error => {
        console.error('Erreur lors du chargement des propriétaires :', error);
        return of([]); // Retourne un tableau vide en cas d'erreur
      })
    );
  }
  loadRentals() {
    return this.http.get<any[]>(this.url + '/private/agency/rental/filter').pipe(
      catchError(error => {
        console.error('Erreur lors du chargement des locations :', error);
        return of([]); // Retourne un tableau vide en cas d'erreur
      })
    );
  }
  change(item, value){
    item.value = value ? value : null
  }
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months+2;
  }
}
