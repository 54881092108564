import {Rent} from '@model/rent';
import {Renew} from '@model/renew';
import {Tenant} from '@model/tenant';
import {Invoice} from '@model/invoice';
import {Contract} from '@model/contract';
import {Penality} from '@model/penality';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import { RentService } from '@service/rent/rent.service';
import { OwnerService } from '@service/owner/owner.service';
import {FilterService} from '@service/filter/filter.service';
import { TenantService } from '@service/tenant/tenant.service';
import {MandateService} from '@service/mandate/mandate.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { ContractService } from '@service/contract/contract.service';
import { RentShowComponent } from '@locataire/rent/rent-show/rent-show.component';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MandateShowComponent} from '@proprietaire/mandate/mandate-show/mandate-show.component';
import {ApexChartService} from "../../theme/shared/components/chart/apex-chart/apex-chart.service";
import { ContractShowComponent } from '@agence/locataire/contract/contract-show/contract-show.component';
HC_drilldown(Highcharts);

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    autre: null,
    bien: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }
  type: string = 'TOUT';
  typeRow = [
    {label: 'TOUT', value: 'TOUT'},
    {label: 'PROPRIETAIRE', value: 'OWNER'},
    {label: 'LOCATION', value: 'LOCATION'},
    {label: 'PROGRAMME IMMOBLIER', value: 'PROGRAMME'},
    {label: 'PROJET DE LOTISSEMENT', value: 'LOTISSEMENT'}
  ];

  autreTitle = "Propriétaire";
  autre: boolean = false;
  isPrint: boolean = true;
  autreType = 'ENTITY';
  autreClass= 'Owner';
  autreNamespace= 'Client';
  autreGroups= 'owner';

  bienTitle: string = "Nom du bien"
  bien: boolean = false
  bienType = 'ENTITY';
  bienClass= 'House';
  bienNamespace= 'Client';
  bienGroups= 'house';

  nameTitle: string = "Locataire"
  name: boolean = false;
  nameType = 'TEXT';
  nameClass= 'Tenant';
  nameNamespace= 'Client';
  nameGroups= 'tenant';

  // VARIABLE POUR LE DASH DEFAULT
  widget : any
  listing : any
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  etat: boolean = false
  publicUrl = environment.publicUrl;
  totalTenant = 0;
  totalRentS = 0;
  totalRentA = 0;
  total = [];
  totalI = [];
  totalP = [];
  mois = [];
  public bandeC: any;
  public bandeM: any;
  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  public lastDate: number;
  public data: any;
  public intervalSub: any;
  public intervalMain: any;

  // DASH OWNER
  pieLocativeO = 0;
  pieLocativeR = 0;
  pieLocativeD = 0;
  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  // DASH LOCATION
  pieFactureA = 0
  pieFactureI = 0
  pieFactureE = 0
  pieFactureS = 0

  //variable du graph
  totalTenantI = 0
  renews: Renew[];
  payments = [];
  rents: Rent[];
  penalities: Penality[];
  invoices: Invoice[];
  autres: Invoice[];
  bande: any
  circulaire: any
  tenantI: Tenant[];
  contractA: Contract[];
  contractR: Contract[];
  contractRe: Contract[];
  rentI = []
  rentT = []
  rentP = []
  widgetTotalTitle: string = "Facture(s)";
  widgetCoursTitle: string = "En cours(s)";
  widgetAttenteTitle: string = "Attente(s)";
  widgetSoldeTitle: string = "Soldé(s)";
  widgetImpayeTitle: string = "Impayée(s)";

  // DASH LOCATAIRE
  public pieChartDataLocation: any;
  @ViewChild('doughnutChartLocation', {static: false}) doughnutChartLocation: ElementRef; // doughnut
  public pieChartTagLocation: CanvasRenderingContext2D;

  // DASH PROMOTION
  pieHouseVe = 0
  pieHouseRe = 0
  pieHouseDi = 0

  //variable du graph
  public lineBasicChartOptions: any;
  echeanciers : []
  ech = [];
  echI = [];
  echP = [];
  public pieChartDataProgramme: any;
  @ViewChild('doughnutChartProgramme', {static: false}) doughnutChartProgramme: ElementRef; // doughnut
  public pieChartTagProgramme: CanvasRenderingContext2D;

  // DASH LOTISSEMENT
  pieLotVe = 0
  pieLotRe = 0
  pieLotDi = 0
  public pieChartDataLotissement: any;
  @ViewChild('doughnutChartLotissement', {static: false}) doughnutChartLotissement: ElementRef; // doughnut
  public pieChartTagLotissement: CanvasRenderingContext2D;

  constructor(
    public router: Router,
    private modalService: NgbModal,
    public apexEvent: ApexChartService,
    private filterService: FilterService,

    // DASH DEFAULT
    private rentService: RentService,
    private ownerService: OwnerService,
    private tenantService: TenantService,
    private contractService: ContractService,
    // DASH OWNER
    private mandateService: MandateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.onFilter(this.event)
    if(this.type === 'TOUT'){
      this.data = [];
      this.total = []
      this.totalP = []
      this.totalI = []
      this.mois = []
      this.bandeC = {
        chart: {
          type: 'area',
          height: 100,
          sparkline: { enabled: true }
        },
        colors: ['#4680ff'],
        stroke: { curve: 'smooth', width: 2, },
        series: [ { name: 'Contrat', data: [] }, ],
        tooltip: {
          fixed: { enabled: false },
          x: { show: false },
          y: { title: { formatter: (seriesName) => 'CONTRAT(S) ' } },
          marker: { show: false }
        }
      };
      this.bandeM = {
        chart: {
          type: 'area',
          height: 100,
          sparkline: { enabled: true }
        },
        colors: ['#9ccc65'],
        stroke: { curve: 'smooth', width: 2, },
        series: [ { name: 'Mandat', data: [] }, ],
        tooltip: {
          fixed: { enabled: false },
          x: {  show: false },
          y: { title: { formatter: (seriesName) => 'MANDAT(S) ' } },
          marker: { show: false }
        }
      };
      this.lastDate = 0;
      this.getDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 10, {min: 10, max: 90});
    }
    this.total = []
    this.totalP = []
    this.totalI = []
    this.ech = []
    this.echP = []
    this.echI = []
    this.mois = []
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    if(this.type === 'OWNER'){
      this.filterService.dashboard(this.event, 'owner', null).subscribe(
        res => {
          this.onSet(res);
          this.getPie();
          this._changeDetectorRef.markForCheck();
      }, err => { })
    }else if(this.type === 'LOCATION') {
      this.getPieLocation();
      this.getGraphLocation();
      this.filterService.dashboard(this.event, 'tenant', null).subscribe(
        res => {
          this.onSetLocation(res);
          this.getGraphLocation();
          this.getPieLocation();
      }, err => { })
    }else if(this.type === 'PROGRAMME') {
      this.getPieProgramme();
      // this.getGraph();
      this.filterService.dashboard(this.event, 'promotion', null).subscribe(
        res => {
          this.onSetProgramme(res);
          this.getGraphProgramme();
          this.getPieProgramme();
      }, err => { })
    }else if(this.type === 'LOTISSEMENT'){
      this.getPieLotissement();
      this.getGraphLotissement();
      this.filterService.dashboard(this.event, 'lotissement', null).subscribe(
        res => {
          this.onSetLotissement(res);
          this.getGraphLotissement();
          this.getPieLotissement();
      }, err => { })
    }
  }

  onFilter($event) {
    this.type = $event.type
    if(this.type === 'TOUT'){
      this.filterService.dashboard($event, 'default', null).subscribe(
        res => {

          this.getGraph();
          this.etat = res ? true : false
          this.widget = res?.widget
          this.total = []
          this.totalP = []
          this.totalI = []
          this.mois = []
          this.listing = res?.listing
          res?.facture.forEach(el => {
            this.total.push(el?.total)
            this.totalP.push(el?.paye)
            this.totalI.push(el?.impaye)
            this.mois.push(el?.date)
          });
          this.listing?.tenant.forEach(el => { this.totalTenant = this.totalTenant + el.impaye});
          this.listing?.rentS.forEach(el => { this.totalRentS = this.totalRentS + el.montant});
          this.listing?.rentA.forEach(el => { this.totalRentA = this.totalRentA + el.montant});

          var totalC = []
          var totalM = []
          if (res) {
            res?.contrat.forEach(el => {
              totalC.push(el?.nbr)
            });
            res?.mandat.forEach(el => {
              totalM.push(el?.nbr)
            });
            this.bandeC.series[0].data = totalC.map(x => x)
            this.bandeM.series[0].data = totalM.map(x => x)
          }
      }, err => { })
    }else if(this.type === 'OWNER'){
      this.filterService.dashboard($event, 'owner', null).subscribe(
        res => {
          this.etat = res ? true : false;
          this.widget = res?.widget;
          this.listing = res?.listing;
          this.onSet(res);
          this.getPie();
          this._changeDetectorRef.markForCheck();
      }, err => { })
    }else if(this.type === 'LOCATION'){
      this.filterService.dashboard($event, 'tenant', null).subscribe(
        res => {
          this.etat = res ? true : false
          this.widget = res?.widget
          this.tenantI = res?.tenantI;
          this.contractA = res?.contractA;
          this.contractR = res?.contractR;
          this.contractRe = res?.contractRe;
          // @ts-ignore
          this.tenantI.forEach(item => { return this.totalTenantI = this.totalTenantI + item?.impaye })
          this.onSetLocation(res);
          this.getPieLocation();
      }, err => { })
    }else if(this.type === 'PROGRAMME'){
      this.getPieProgramme();
      // this.getGraph();
      this.filterService.dashboard($event, 'promotion', null).subscribe(
        res => {
          this.onSetProgramme(res);
          this.getGraphProgramme();
          this.getPieProgramme();
      }, err => { })
    }else if(this.type === 'LOTISSEMENT'){
      this.type = $event.type
      this.filterService.dashboard($event, 'lotissement', null).subscribe(
        res => {
          this.onSetLotissement(res);
          this.getGraphLotissement();
      }, err => { })
    }
  }
  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "TOUT",
        uuid: null
      }
      this.onFilter(this.event)
    } else if($event === 'LOCATION'){
      this.name = true;
      this.nameTitle = 'Locataire'
      this.nameType = 'ENTITY';
      this.nameClass= 'Tenant';
      this.nameNamespace= 'Client';
      this.nameGroups= 'tenant';

      this.bien = true;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Propriétaire";
      this.autre = true;
      this.autreType = 'ENTITY';
      this.autreClass= 'Owner';
      this.autreNamespace= 'Client';
      this.autreGroups= 'owner';

      this.widgetTotalTitle = "Facture(s)";
      this.widgetCoursTitle = "En cours(s)";
      this.widgetAttenteTitle = "Attente(s)";
      this.widgetSoldeTitle = "Soldé(s)";
      this.widgetImpayeTitle = "Impayée(s)";
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "LOCATION",
        uuid: null
      }
      this.onFilter(this.event)
    } else if($event === 'OWNER'){
      this.name = false;
      this.nameTitle = 'Locataire'
      this.nameType = 'ENTITY';
      this.nameClass= 'Tenant';
      this.nameNamespace= 'Client';
      this.nameGroups= 'tenant';

      this.bien = true;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Propriétaire";
      this.autre = true;
      this.autreType = 'ENTITY';
      this.autreClass= 'Owner';
      this.autreNamespace= 'Client';
      this.autreGroups= 'owner';
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "OWNER",
        uuid: null
      }
      this.onFilter(this.event)
    } else if($event === 'PROGRAMME'){
      this.name = true;
      this.nameTitle = 'Promotion'
      this.nameType = 'ENTITY';
      this.nameClass= 'Promotion';
      this.nameNamespace= 'Client';
      this.nameGroups= 'promotion';

      this.bien = false;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Propriétaire";
      this.autre = false;
      this.autreType = 'ENTITY';
      this.autreClass= 'Owner';
      this.autreNamespace= 'Client';
      this.autreGroups= 'owner';
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "PROGRAMME",
        uuid: null
      }
      this.onFilter(this.event)
    } else if($event === 'LOTISSEMENT'){
      this.name = true;
      this.nameTitle = 'Projet de lotissement'
      this.nameType = 'ENTITY';
      this.nameClass= 'Subdivision';
      this.nameNamespace= 'Client';
      this.nameGroups= 'subdivision';

      this.bien = false;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Propriétaire";
      this.autre = false;
      this.autreType = 'ENTITY';
      this.autreClass= 'Owner';
      this.autreNamespace= 'Client';
      this.autreGroups= 'owner';
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "LOTISSEMENT",
        uuid: null
      }
      this.onFilter(this.event)
    } else if($event === 'CRM'){
      this.name = true;
      this.nameTitle = 'Promotion'
      this.nameType = 'ENTITY';
      this.nameClass= 'Promotion';
      this.nameNamespace= 'Client';
      this.nameGroups= 'promotion';

      this.bien = true;
      this.bienTitle = 'Projet de lotissement';
      this.bienType = 'ENTITY';
      this.bienClass= 'Subdivision';
      this.bienNamespace= 'Client';
      this.bienGroups= 'subdivision';

      this.autreTitle = "Propriétaire";
      this.autre = false;
      this.autreType = 'ENTITY';
      this.autreClass= 'Owner';
      this.autreNamespace= 'Client';
      this.autreGroups= 'owner';
      this.event = {
        categorie: null,
        code: null,
        count: 10,
        create: null,
        dateD: null,
        dateF: null,
        etat: null,
        max: null,
        min: null,
        name: null,
        autre: null,
        bien: null,
        ordre: "ASC",
        type: "CRM",
        uuid: null
      }
      this.onFilter(this.event)
    }
  }



  // DASH DEFAULT
  getGraph() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'STATISTIQUES DE RECOUVREMENT MENSUEL DES LOYERS'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total',
        data: this.total

      }, {
        color: 'green',
        name: 'Payé',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Impayée',
        data: this.totalI
      },]
    };
  }
  showOwner(row): void {
    this.ownerService.setOwner(row);
    this.router.navigate(['/admin/proprietaire/show/' + row.uuid]);
  }
  showTenant(row) {
    this.tenantService.setTenant(row);
    this.router.navigate(['/admin/locataire/show/' + row.uuid]);
  }
  showRent(row) {
    this.rentService.setRent(row)
    this.modal(RentShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  // DASH OWNeR
  onSet(res){
    if (res) {
      this.pieLocativeO = res?.pieLocative.nbrO;
      this.pieLocativeR = res?.pieLocative.nbrR;
      this.pieLocativeD = res?.pieLocative.nbrD;
    }
  }
  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ["Occupée", "Reservée", 'Disponible'],
        datasets: [{
          data: [this.pieLocativeO, this.pieLocativeR, this.pieLocativeD],
          backgroundColor: ['#0e9e4a', '#4680ff', '#ff5252'],
          hoverBackgroundColor: ['#0e9e4a', '#4680ff', '#ff5252']
        }]
      };
    });
  }
  showMandate(row) {
    this.mandateService.setMandate(row);
    this.modal(MandateShowComponent, 'modal-basic-title', 'lg', true, 'static');
  }
  // DASH LOCATION
  showContract(row): void {
    this.contractService.setContract(row);
    this.modal(ContractShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  onSetLocation(res){
    this.getGraphLocation();
    this.rentT = []
    this.rentP = []
    this.rentI = []
    this.mois = []
    if (res) {
      res?.facture?.forEach(el => {
        this.rentT.push(el?.total)
        this.rentP.push(el?.paye)
        this.rentI.push(el?.impaye)
        this.mois.push(el?.date)
      });
      this.pieFactureA = res?.pieFacture.nbrA
      this.pieFactureI = res?.pieFacture.nbrI
      this.pieFactureE = res?.pieFacture.nbrE
      this.pieFactureS = res?.pieFacture.nbrS
    }
  }
  getGraphLocation () {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a', '#4680ff', '#ff5252'],
      title: {
        text: "DIAGRAMME EN BANDE DES MONTANTS, TOTAL, IMPAYE ET PAYE EN FONCTION D'UNE PERIODE."
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
      {
        color: '#0e9e4a',
        name: 'Payé',
        data: this.rentP

      }, {
        color: '#4680ff',
        name: 'Total',
        data: this.rentT

      },{
        color: '#ff5252',
        name: 'Impayeé',
        data: this.rentI

      }
    ]
    };
  }
  getPieLocation(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChartLocation.nativeElement as HTMLCanvasElement).children));
      this.pieChartTagLocation = ((pieTag['doughnut_chart_location']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartDataLocation = {
        labels: ['Attente', 'Impayé', 'En cours', 'Soldé'],
        datasets: [{
          data: [this.pieFactureA, this.pieFactureI, this.pieFactureE, this.pieFactureS],
          backgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a']
        }]
      };
    });
  }
  // DASH PRGRAMME
  onSetProgramme(res){
    this.etat = res ? true : false
    this.widget = res?.widget
    this.total = []
    this.totalP = []
    this.totalI = []
    this.ech = []
    this.echP = []
    this.echI = []
    this.mois = []
    this.listing = res?.listing
    this.echeanciers = res?.echeanciers
    res?.facture.forEach(el => {
      this.total.push(el?.total)
      this.totalP.push(el?.paye)
      this.totalI.push(el?.impaye)
      this.mois.push(el?.date)
    });
    res?.echeanciers.forEach(el => {
      this.ech.push(el?.prev)
      this.echP.push(el?.reg)
      this.echI.push(el?.reg > el?.prev ? 0 : el?.prev - el?.reg)
    });
    this.pieHouseVe = res?.pieHouse.ve
    this.pieHouseRe = res?.pieHouse.re
    this.pieHouseDi = res?.pieHouse.di
  }
  getGraphProgramme() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'STATISTIQUE DES PAIEMENTS'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total prévisionnel',
        data: this.total

      }, {
        color: 'green',
        name: 'Total recouvré',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Payé restant',
        data: this.totalI
      },]
    };


    this.lineBasicChartOptions = {
      chart: {
        type: 'spline',
      },
      colors: ['#D9F0F3', 'green', 'red'],
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Total prévisionnel',
        data: this.ech
      }, {
        name: 'Total recouvré',
        data: this.echP
      }, {
        name: 'Payé restant',
        data: this.echI
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };
  }
  getPieProgramme(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChartProgramme.nativeElement as HTMLCanvasElement).children));
      this.pieChartTagProgramme = ((pieTag['doughnut_chart_programme']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartDataProgramme = {
        labels: ['Occupé', 'Reservé', 'Disponible'],
        datasets: [{
          data: [this.pieHouseVe, this.pieHouseRe, this.pieHouseDi,],
          backgroundColor: ['#ff5252','#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#ff5252','#ffa21d', '#0e9e4a']
        }]
      };
    });
  }
  // DASH LOTISSEMENT
  onSetLotissement(res){
    this.etat = res ? true : false
    this.widget = res?.widget
    this.total = []
    this.totalP = []
    this.totalI = []
    this.ech = []
    this.echP = []
    this.echI = []
    this.mois = []
    this.listing = res?.listing
    this.echeanciers = res?.echeanciers
    res?.facture.forEach(el => {
      this.total.push(el?.total)
      this.totalP.push(el?.paye)
      this.totalI.push(el?.impaye)
      this.mois.push(el?.date)
    });
    res?.echeanciers.forEach(el => {
      this.ech.push(el?.prev)
      this.echP.push(el?.reg)
      this.echI.push(el?.reg > el?.prev ? 0 : el?.prev - el?.reg)
    });
    this.pieLotVe = res?.pieLot.ve
    this.pieLotRe = res?.pieLot.re
    this.pieLotDi = res?.pieLot.di
  }
  getGraphLotissement() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'STATISTIQUE DES PAIEMENTS'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total prévisionnel',
        data: this.total

      }, {
        color: 'green',
        name: 'Total recouvré',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Payé restant',
        data: this.totalI
      },]
    };


    this.lineBasicChartOptions = {
      chart: {
        type: 'spline',
      },
      colors: ['#D9F0F3', 'green', 'red'],
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Total prévisionnel',
        data: this.ech
      }, {
        name: 'Total recouvré',
        data: this.echP
      }, {
        name: 'Payé restant',
        data: this.echI
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };
  }
  getPieLotissement(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChartLotissement.nativeElement as HTMLCanvasElement).children));
      this.pieChartTagLotissement = ((pieTag['doughnut_chart_lotissement']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartDataLotissement = {
        labels: ['Occupé', 'Reservé', 'Disponible'],
        datasets: [{
          data: [this.pieLotVe, this.pieLotRe, this.pieLotDi,],
          backgroundColor: ['#ff5252','#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#ff5252','#ffa21d', '#0e9e4a']
        }]
      };
    });
  }

  ngOnDestroy() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }
    if (this.intervalMain) {
      clearInterval(this.intervalMain);
    }
  }
  getDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0;
    while (i < count) {
      const x = baseval;
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      this.data.push({x, y});
      this.lastDate = baseval;
      baseval += 86400000;
      i++;
    }
  }
  resetData() {
    this.data = this.data.slice(this.data.length - 10, this.data.length);
  }
  getNewSeries(baseval, yrange) {
    const newDate = baseval + 86400000;
    this.lastDate = newDate;
    this.data.push({
      x: newDate,
      y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  date(date){
    var format = 'MMMM Do, YYYY [at] h:mm:ss A';
    DateHelperService.formatDatefromServer(date, format)
  }
}
