export const environment = {
  production: false,

  // prod
  publicUrl: "https://api.zenapi.immo",
  serverUrl: "https://api.zenapi.immo/api",

  // demo
  // publicUrl: "http://demo.api.zenapi.immo",
  // serverUrl: "http://demo.api.zenapi.immo/api",

  // local
  // serverUrl: "http://127.0.0.1:8000/api",
  // publicUrl: "http://127.0.0.1:8000",

  zenUrl: "http://localhost:8000",
  mercureUrl: "https://zenapi-mercure.herokuapp.com/.well-known/mercure"
};
