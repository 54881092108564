<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label for="email">E-mail <span class="asterix">*</span></label>
                <input type="email" formControlName="email" class="form-control" id="date" [ngClass]="{
                  'is-invalid': submit && f.email.errors,
                  'is-valid': submit && f.email.valid
                }">
                <div class="invalid-feedback" *ngIf="submit && f.email.errors">
                  <div *ngIf="f.email.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="telephone">Contact <span class="asterix">*</span></label>
                <app-contact-mask [selected]="selected" (value)="setContact($event)"
                  [name]="'telephone'" [required]="true" [placeholder]="'Votre pays'"></app-contact-mask>
                <div class="invalid-feedback" *ngIf="submit && f.telephone.errors">
                  <div *ngIf="f.telephone.errors.required">{{required.novide}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onReset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
