import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Construction} from '@model/construction';
import {Funding} from '@model/funding';
import {House} from '@model/house';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConstructionService} from '@service/construction/construction.service';
import {FundingService} from '@service/funding/funding.service';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import { EmitterService } from '@service/emitter/emitter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-funding-add',
  templateUrl: './funding-add.component.html',
  styleUrls: ['./funding-add.component.scss'],
  providers: [DatePipe]
})
export class FundingAddComponent implements OnInit {
  title: string = '';
  edit: boolean = false;
  form: FormGroup;
  selectRow = [];
  submit: boolean = false;
  isHidden: boolean = false;
  funding: Funding;
  house: House;
  fundings = [];
  constructions = [];
  houses = [];
  required = Globals.required
  currentConstruction?: any;
  global = {country: Globals.country, device: Globals.device}
  construction: Construction;

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private fundingService: FundingService,
    private datePipe: DatePipe,
    private emitter: EmitterService,
    private constructionService: ConstructionService,
    public toastr: ToastrService
  ) {
    this.edit = this.fundingService.edit;
    this.funding = this.fundingService.getFunding();
    this.title = (!this.edit) ? 'Ajouter un financement' : 'Modifier le financement ' + this.funding.construction.nom;
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      financeur: [null, [Validators.required]],
      date: [null, [Validators.required]],
      construction: [null, [Validators.required]],
      interet: [0, [Validators.required]],
      montantP: [0, [Validators.required]],
      montantA: [0, [Validators.required]],
      mois: [0, [Validators.required]],
      options: this.formBuild.array([]),
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.fundingService.getFunding()};
      this.setConstructionUuid(data.construction.uuid);
      data.date = DateHelperService.fromJsonDate(data?.date);
      this.construction = data?.construction
      this.currentConstruction ={
        photoSrc: data?.construction?.photoSrc,
        title: data?.construction?.nom,
        detail: data?.construction?.budget
      };
      this.form.patchValue(data);
      this.onChangeTotal()
    }
  }
  setConstructionUuid(uuid) {
    this.f.construction.setValue(uuid);
    if(!this.edit && uuid){
      this.constructionService.getSingle(uuid).subscribe((res: any) => {
        this.construction = res
        return this.construction;
      });
    }
    if(!uuid){
      this.f.date.setValue(null);
      this.f.financeur.setValue(null);
      this.f.interet.setValue(0);
      this.f.mois.setValue(0);
      this.f.montantA.setValue(0);
      this.f.montantP.setValue(0);
      this.onChangeTotal()
    }
  }
  onChangeTotal() {
    if (this.f.financeur.value === 'PROPRIETAIRE') {
      this.f.montantP.setValue(this.construction?.budget),
      this.f.montantA.setValue(0)
      this.f.mois.setValue(0)
      this.f.interet.setValue(0)
    }else{
      this.f.montantA.setValue(this.construction?.budget >= 0 && this.f.montantP.value >= 0 ? this.construction?.budget - this.f.montantP.value : 0)
    }
    this.isHidden = this.f.mois.value > 0 && this.f.interet.value > 0 ? true : false
    this.option.controls.length = 0;
    this.option.controls = [];
    var nbr = (this.f.mois.value >= 0) ? this.f.mois.value : 0;
    if (this.option.controls.length < nbr && this.f.interet.value >= 0 && this.f.financeur.value === 'AGENCE') {
      var montantA = (this.construction ? this.construction.budget : 0) - this.f.montantP.value;
      montantA > 0 ? this.f.montantA.setValue(montantA) : this.f.montantA.setValue(0)
      var capital = this.f.montantA.value
      var taux = this.f.interet.value / 100
      var capitalF = 0
      var annuiteC = 0
      for(let i = 1; i <= this.f.mois.value; i++){
        let date = new Date(this.f.date.value);
        date.setMonth(date.getMonth() + (i-1));
        var dateF = this.datePipe.transform(date , 'MMMM-yyyy');

        var capitalD = i === 1 ? capital : capitalF;
        var interet = Math.round(capitalD * taux)
        var mensu = i === 1 ? Math.round(interet/( 1 - Math.pow((1 + taux), (-this.f.mois.value)) )) : annuiteC
        annuiteC = mensu
        var amort = Math.round(mensu - interet)
        capitalF = i === this.f.mois.value ? 0 : capitalD - amort
        this.option.push(
          this.formBuild.group({
            uuid: [null],
            id: [null],
            periode: [{value: dateF , disabled: true},, [Validators.required]],
            capital: [{value: capitalD , disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            interet: [{value: interet , disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
            ammortissement: [{value: amort , disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
            mensualite: [{value: mensu , disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            valeur: [{value: capitalF, disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          })
        );
      }
      return this.option;
    } else if (nbr === 0)  {
      let i = this.option.controls.length - (nbr === 0 ? 1 : nbr);
      return this.option.removeAt(i);
    } else {
      return this.option.controls.splice(0, this.option.controls.length);
    }
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
        this.fundingService.add(data).subscribe(res => {
          if (res?.status === 'success') {
            this.modal.close('ferme');
            if (data?.uuid) {
              this.emitter.emit({action: 'FUNDING_UPDATED', payload: res?.data});
            } else {
              this.emitter.emit({action: 'FUNDING_ADD', payload: res?.data});
            }
          }
        });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  timelapse(dateD, dateF): string {return DateHelperService.getTimeLapse(dateD, dateF, false, 'dmy');}
  get f() {return this.form.controls;}
  get option() { return this.form.get('options') as FormArray; }
}
