<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{ spent?.code }}</span>
      </p>
      <p>
        <span class="title-show">Trésorerie : </span>
        <span class="title-result-show"> {{ spent?.treasury?.nom }} </span>
      </p>
      <p>
        <span class="title-show">Date : </span>
        <span class="title-result-show">établie le {{spent?.date | date:'dd/MM/yyyy'}}</span>
      </p>
      <p>
        <span class="title-show">Priorité : </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
            'badge-danger' : spent?.priorite === 'URGENT',
            'badge-warning' : spent?.priorite === 'MOYEN',
            'badge-success' : spent?.priorite === 'NON'
            }"> {{ spent?.priorite === 'MOYEN' ? 'PRIORITE MOYEN' : spent?.priorite === 'NON' ? 'NON PRIORITAIRE' : spent?.priorite }}
          </span>
        </span>
      </p>
      <p>
        <span class="title-show">Etat: </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
            'badge-success' : spent?.etat === 'VALIDE',
            'badge-danger' : spent?.etat === 'INVALIDE'
            }">{{ spent?.etat }}
          </span>
        </span>
      </p>
      <p *ngIf="spent?.type === 'PROPRIETAIRE'">
        <span class="title-show">Propriétaire : </span>
        <span class="title-result-show"> {{ spent?.house?.owner?.nom }} </span>
      </p>
      <p *ngIf="spent?.type === 'PROPRIETAIRE'">
        <span class="title-show">Bien concerné : </span>
        <span class="title-result-show"> {{ spent?.house?.nom }} </span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p *ngIf="spent?.type === 'PROPRIETAIRE'">
        <span class="title-show">Reversé: </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
            'badge-success' : spent?.reverse === 'OUI',
            'badge-warning' : spent?.reverse === 'NON'
            }">{{ spent?.reverse }}
          </span>
        </span>
      </p>
      <p>
        <span class="title-show">Statut : </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
            'badge-danger' : spent?.status === 'ATTENTE',
            'badge-success' : spent?.status === 'CONFIRMER'
            }">{{ spent?.status }}
          </span>
        </span>
      </p>
      <p>
        <span class="title-show text-warning">Montant  : </span>
        <span class="title-result-show text-warning">{{ spent?.montant|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show">Bénéficiaire : </span>
        <span class="title-result-show"> {{ spent?.receiver }} </span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{spent?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ spent?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ spent?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{spent?.update }}</span>
      </p>
      <p>
        <span class="title-show">Validé le : </span>
        <span class="title-result-show">{{ spent?.validateAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{spent?.validate }}</span>
      </p>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Prix u.</th>
          <th>Qte</th>
          <th>Tva</th>
          <th>Remise</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of spent?.options" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{ item?.libelle }}</p></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{ item?.prix |number }} {{ global.device }}</span></p>
          </td>
          <td>{{ item?.qte }}</td>
          <td>{{ item?.tva }} %</td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{ item?.remise |number }} {{ global.device }}</span></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{ item?.total |number }} {{ global.device }}</span></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive invoice-table invoice-total">
        <tbody>
        <tr>
          <th>TOTAL HT : </th>
          <td>{{ spent?.montantHt | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL REMISE : </th>
          <td>{{ spent?.montantRemise | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL TVA : </th>
          <td>{{ spent?.montantTva | number }} {{global.device}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
        <td class="font-weight-bold font-size-default text-right">{{ spent?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template ngxPermissionsOnly="SPENT:PRINTER:SHOW">
    <button *ngIf="spent?.etat === 'VALIDE'" (click)="printerSpent(spent)" class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
    </button>
  </ng-template>
</div>
