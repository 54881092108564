<div class="modal-header">
  <h5 class="modal-title">{{title}} {{this.reports.dateD|date}} </h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="col-md-12">
  <div class="col-md-12">
    <span class="badge badge-warning my-2 f-14 formBadge width">TÂCHES EFFECTUÉES</span>
  </div>
  <table datatable class="table table-sm table-striped table-bordered nowrap table-hover">
    <thead class="thead-light">
      <tr>
        <th>Libellé</th>
        <th>Quantité effectué</th>
        <th>Unitée </th>
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reports.options">
        <td>{{item?.task?.libelle}}</td>
        <td>{{item?.task?.qte}}</td>
        <td>{{item?.task?.unite}}</td>
      </tr>
    </tbody>
  </table>
</div>