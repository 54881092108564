
import { Ticket } from '@model/ticket';
import { Router } from "@angular/router";
import { Ressource } from '@model/ressource';
import {environment} from '@env/environment';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from '@service/filter/filter.service';
import { EmitterService } from '@service/emitter/emitter.service';
import { RessourceAddComponent } from '../ressource-add/ressource-add.component';
import { RessourceTiersService } from '@service/ressource-tiers/ressource-tiers.service';
import { RessourceAssignComponent } from '../ressource-assign/ressource-assign.component';

@Component({
  selector: 'app-ressource-list',
  templateUrl: './ressource-list.component.html',
  styleUrls: ['./ressource-list.component.scss']
})
export class RessourceListComponent implements OnInit {
  ressources: Ressource[] = []
  etat: boolean = false
  type = "RESSOURCE";
  widget : any
  filter : any
  visible: boolean = false;
  public loading = false;
  tickets: Ticket;
  public showComment = false;
  dtOptions: any = {};
  typeRow = [{label: 'RESSOURCE', value: 'RESSOURCE'}];
  categorieRow = [
    {label: 'Ressource de base', value: 'BASE'},
    {label: 'Ressource composite', value: 'COMPOSITE'}
  ];
  userTitle: string = "Crée par"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de Ressource"
  etatTitle: string = "Etat"

  nameTitle: string = "Désignation "
  name: boolean = true;
  nameType = 'TEXT';
  nameClass= 'Tenant';
  nameNamespace= 'Client';
  nameGroups= 'tenant';

  autreTitle = "Famille";
  autre: boolean = true;
  autreType = 'ENTITY';
  autreClass= 'Owner';
  autreNamespace= 'Client';
  autreGroups= 'owner';
  
  bienTitle: string = "Sous Famille"
  bien: boolean = true
  bienType = 'ENTITY';
  bienClass= 'House';
  bienNamespace= 'Client';
  bienGroups= 'house';
  
  libelleTitle: string = "N° Contrat"
  libelle: boolean = false
  libelleType = 'TEXT';
  libelleClass= 'House';
  libelleNamespace= 'Client';
  libelleGroups= 'house';

  etatRow = [
    { label: "EN STOCK", value: "EN STOCK" },
    { label: "EN UTILISATION", value: "EN UTILISATION" },
    { label: "EN PANNE", value: "EN PANNE" }
  ]
  max: boolean = false;
  min: boolean = false;
  constructor(
    public router: Router,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private filterService: FilterService,
    public ressourceService: RessourceTiersService,
  ) { 
    this.ressourceService.getList().subscribe(res => { 
      return this.ressources = res; 
    }, error => {});

  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'RESSOURCE_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'RESSOURCE_UPDATED') {
        this.update(data.payload);
      }
    });
  }
  appendToList(item): void {
    this.ressources.unshift(item);
  }
  update(item): void {
    const index = this.ressources.findIndex(x => x.uuid === item.uuid);
    if (index !== -1) {
      this.ressources[index] = item;
    }
  }
  onFilter($event) {
    this.filterService.type = this.type;
    this.filter = null
    this.ressources = []
    this.filterService.search($event, 'ressource', null).subscribe(
      res => {
        this.filter = this.filterService.filter
        if(this.type === 'RESSOURCE'){ 
          this.ressources = res;
        }
    }, err => { })
  }
  onChangeLoad($event) {
    this.type = $event
    if($event === 'RESSOURCE'){
    } 
  }
  add() {
    this.modalService.dismissAll();
    this.modal(RessourceAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  edit(item) {
    this.modalService.dismissAll();
    this.ressourceService.edit = true
    this.ressourceService.setRessource(item)
    this.modal(RessourceAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  show(item) {
    this.router.navigate(['/admin/ressource/show/' + item.uuid]);
  }
  assigner(item) {
    this.modalService.dismissAll();
    this.ressourceService.setRessource(item)
    this.modal(RessourceAssignComponent, 'modal-basic-title', 'lg', true, 'static');
  }
  onExport() {}
  onImport() {}
  onModel() {}

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }

}
