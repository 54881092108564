<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence : </span>
        <span class="title-result-show">{{terminate?.code }}</span>
      </p>
      <p>
        <span class="title-show">Locataire : </span>
        <span class="title-result-show">{{ terminate?.contract?.tenant?.searchableTitle }}</span>
      </p>
      <p>
        <span class="title-show">Locative : </span>
        <span class="title-result-show">{{ terminate?.contract?.rental?.libelle }}</span>
      </p>
      <p>
        <span class="title-show">Contrat : </span>
        <span class="title-result-show">{{ terminate?.contract?.libelle }}</span>
      </p>
      <p>
        <span class="title-show">Etat : </span>
        <span class="title-result-show">
          <span class="badge" [ngClass]="{
          'badge-success' : terminate?.etat === 'VALIDE',
          'badge-danger' : terminate?.etat === 'INVALIDE'
          }">{{validation(terminate?.etat)}}</span>
        </span>
      </p>
    </div>
    <!-- COL MILLIEU -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Date : </span>
        <span class="title-result-show">{{ terminate?.date|date: "d MMMM y" : '' : 'fr-FR' }}</span>
      </p>
      <p>
        <span class="title-show">Au crédit : </span>
        <span class="title-result-show">
          <span class="badge badge-primary">
            {{ terminate?.signe === 'NEGATIF' ? "Au bénéfice de l'agence" : "Au bénéfice du locataire" }}
          </span>
        </span>
      </p>
      <p>
        <span class="title-show ">Montant à retourner : </span>
        <span class="title-result-show text-warning">{{ terminate?.montantRetour|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show ">Montant à déduire : </span>
        <span class="title-result-show text-warning">{{ terminate?.montantDeduire|number }} {{global.device}}</span>
      </p>
      <p>
        <span class="title-show ">Montant : </span>
        <span class="title-result-show text-warning">{{ terminate?.montant|number }} {{global.device}}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{terminate?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ terminate?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ terminate?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{terminate?.update }}</span>
      </p>
      <p>
        <span class="title-show">Validé le : </span>
        <span class="title-result-show">{{ terminate?.validateAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{terminate?.validate }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <!-- TABLEAU DES OPTIONS DE FACTURES -->
    <div class="col-md-6">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 mr-1 f-14 formBadge width">
          DETAILS DES ELEMENTS A RENDRE AU LOCATAIRE
        </span>
      </div>
      <div class="row mr-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Désignation</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of terminate?.returns">
                <td>{{item?.libelle }}</td>
                <td>{{item.prix|number}} {{global.device}} </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="font-weight-bold font-size-40 text-right">TOTAL</td>
                <td class="font-weight-bold font-size-40 text-danger"> {{ terminate?.montantRetour | number}} {{global.device}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row mb-2">
        <span class="badge badge-secondary my-2 f-14 formBadge width">
          DETAILS DES ELEMENTS DEDUCTIBLES AU LOCATAIRE
        </span>
      </div>
      <div class="row ml-1">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th>Désignation</th>
                <th>Impayé</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of terminate?.deducts">
                <td>{{item?.libelle }}</td>
                <td>{{item.prix|number}} {{global.device}} </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="font-weight-bold font-size-40 text-right">TOTAL</td>
                <td class="font-weight-bold font-size-40 text-danger"> {{ terminate?.montantDeduire | number}} {{global.device}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLEAU DES OPTIONS DE FACTURES -->
  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Prix u.</th>
          <th>Qte</th>
          <th>Tva</th>
          <th>Remise</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of terminate?.invoice?.options" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix|number}} {{global.device}}</span></p>
          </td>
          <td>{{item?.qte}}</td>
          <td>{{item?.tva}} %</td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise|number}} {{global.device}}</span></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{(item?.total)|number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- TOTAL DES OPTIONS -->
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive invoice-table invoice-total">
        <tbody>
          <tr>
            <th>TOTAL HT : </th>
            <td> {{ terminate?.invoice?.montantHt | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL REMISE : </th>
            <td> {{ terminate?.invoice?.montantRemise | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL TVA : </th>
            <td> {{ terminate?.invoice?.montantTva | number }} {{global.device}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- TOTAL GLOBAL -->
  <table class="width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
        <td class="font-weight-bold font-size-default text-right">{{ terminate?.invoice?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template ngxPermissionsOnly="TERMINATE:EDIT">
    <button *ngIf="terminate?.etat === 'INVALIDE'" (click)="editTerminate(terminate)" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
    </button>
  </ng-template>
  <button *ngxPermissionsOnly="'TERMINATE:PRINTER:SHOW'" (click)="printerTerminate(terminate)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
