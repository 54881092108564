import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.scss']
})
export class ProcedureListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
