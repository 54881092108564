<div *ngIf="this.isConfig" id="styleSelector" class="menu-styler" [ngClass]="{'open': styleSelectorToggle}">
    <div class="style-toggler">
        <a href="javascript:" (click)="styleSelectorToggle = !styleSelectorToggle;"></a>
    </div>
    <div class="style-block">
        <h4 class="mb-2">Liste des lotissements</h4>
        <hr />
        <div class="p-2">
            <div class="input-group mb-0">
                <input type="text" class="form-control add_task_todo" placeholder="Rechercher..." required="">
                <div class="input-group-append">
                    <button class="btn waves-effect waves-light btn-primary btn-msg-send" type="button"><i
                            class="fas fa-search"></i></button>
                </div>
            </div>
        </div>

        <ul class="list-group list-group-flush p-0">
            <li *ngFor="let item of lotissements" class="list-group-item mb-2" (click)="onClick(item)">
                <div class="border rounded p-1">
                    <div class="d-flex align-items-center">
                        <div class="cover-img-block mr-2">
                            <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/promotion-default.jpg'"
                                class="img-fluid size-img-house" style="width: 80px; height:90px;">
                        </div>
                        <div>
                            <div>{{ item?.nom }}</div>
                            <div>Situé: {{ item?.quartier + ' - ' + item?.commune+ ' - ' + item?.ville }}</div>
                            <div class="d-flex align-items-center">
                                <span class="badge badge-light mr-2">{{ item?.nbrIlot }} ilot(s)</span>
                                <span class="badge badge-light">{{ item?.nbrLot }} lot(s)</span>
                            </div>
                            <span class="text-primary">Total : {{item?.nbrLot}} Lot(s)</span> <br />
                            <span class="text-success">Disponible : {{item?.nbrLotD}} Lot(s)</span><br />
                            <span class="text-warning">Reservé : {{item?.nbrLotR}} Lot(s)</span><br />
                            <span class="text-danger">Vendu : {{item?.nbrLotV}} Lot(s)</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>