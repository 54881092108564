import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashViewerService {
  public form = null;
  public entity =null;

  constructor() { }
}
