import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {OwnerService} from '@service/owner/owner.service';
import {DashViewerService} from '@service/dash-viewer/dash-viewer.service';
import { OwnerAddComponent } from '@proprietaire/owner/owner-add/owner-add.component';
import {TenantAddComponent} from "@locataire/tenant/tenant-add/tenant-add.component";
import {TenantService} from "@service/tenant/tenant.service";
import {CustomerService} from "@service/customer/customer.service";
import {RentalService} from "@service/rental/rental.service";
import { RentalAddComponent } from '@agence/proprietaire/rental/rental-add/rental-add.component';
import { CustomerAddComponent } from '@agence/client/customer/customer-add/customer-add.component';
import {ContractService} from "@service/contract/contract.service";
import {MandateService} from "@service/mandate/mandate.service";
import {HouseService} from "@service/house/house.service";
import {TicketService} from "@service/ticket/ticket.service";
import {ConstructionService} from "@service/construction/construction.service";
import {ProviderService} from "@service/provider/provider.service";
import {InvoiceService} from "@service/invoice/invoice.service";
import {ContractShowComponent} from "@locataire/contract/contract-show/contract-show.component";
import {ContractAddComponent} from "@locataire/contract/contract-add/contract-add.component";
import {MandateShowComponent} from "@agence/syndic/mandate/mandate-show/mandate-show.component";
import {MandateAddComponent} from "@agence/syndic/mandate/mandate-add/mandate-add.component";
import {HouseAddComponent} from "@proprietaire/house/house-add/house-add.component";
import {DateHelperService} from "@theme/utils/date-helper.service";
import {ConstructionAddComponent} from "@chantier/construction/construction-add/construction-add.component";

@Component({
  selector: 'app-dash-viewer',
  templateUrl: './dash-viewer.component.html',
  styleUrls: ['./dash-viewer.component.scss']
})
export class DashViewerComponent implements OnInit {
  entity = null;
  form = null;
  datas: any[] = [];
  dtOptions: any = {};
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  modelRef: NgbModalRef;
  constructor(
    public router: Router,
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    public bienService: HouseService,
    public ownerService: OwnerService,
    public ticketService: TicketService,
    public tenantService: TenantService,
    public rentalService: RentalService,
    public mandatService: MandateService,
    public invoiceService: InvoiceService,
    public viewerSevice: DashViewerService,
    public contratService: ContractService,
    public providerService: ProviderService,
    public customerService: CustomerService,
    public constructionService: ConstructionService
  ) {
    this.entity = this.viewerSevice.entity;
    this.form = this.viewerSevice.form;

    this.loadData();
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
  }

  loadData(): void {
    if(this.entity && this.entity === 'OWNER'){
      this.ownerService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })
    }else if (this.entity && this.entity === 'TENANT'){
      this.tenantService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'CUSTOMER'){
      this.customerService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'LOCATIVE'){
      this.rentalService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'CONTRAT'){
      this.contratService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'MANDAT'){
      this.mandatService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'BIENS'){
      this.bienService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'TICKET'){
      this.ticketService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'INTERVENTION'){
      this.constructionService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'FOURNISSEUR'){
      this.providerService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }else if (this.entity && this.entity === 'INVOICE'){
      this.providerService.getDash(this.form).subscribe((res) => {
        res.data.forEach(item => {
          this.datas.push(item);
        });
        console.log(this.datas.length && this.datas);
        return this.datas
      } , err => { })

    }
  }

  editOwner(row) {
    this.ownerService.setOwner(row);
    this.ownerService.edit = true;
    this.ownerService.type = row.type;
    this.modal(OwnerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showOwner(row) {
    this.ownerService.setOwner(row);
    this.router.navigate(['/admin/proprietaire/show/' + row.uuid]);
  }
  printerOwner(row): void {
    this.ownerService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  editTenant(row) {
    this.tenantService.setTenant(row);
    this.tenantService.edit = true;
    this.tenantService.type = row.type;
    this.modal(TenantAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showTenant(row) {
    this.tenantService.setTenant(row);
    this.router.navigate(['/admin/locataire/show/' + row.uuid]);
  }
  printerTenant(row): void {
    this.tenantService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  editClient(row) {
    this.customerService.setCustomer(row);
    this.customerService.edit = true;
    this.customerService.type = row.type;
    this.modal(CustomerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showClient(row) {
    this.customerService.setCustomer(row);
    this.router.navigate(['/admin/client/show/' + row.uuid]);
  }
  printerClient(row): void {
    this.customerService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  editRental(row) {
    this.rentalService.setRental(row);
    this.rentalService.edit = true;
    this.rentalService.type = row.type;
    this.modal(RentalAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showRental(row) {
    this.rentalService.setRental(row);
    this.router.navigate(['/admin/locative/show/' + row.uuid]);
  }
  printerRental(row): void {
    this.rentalService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  editContrat(row) {
    this.contratService.setContract(row);
    this.contratService.edit = true;
    this.modal(ContractAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showContrat(row): void {
    this.contratService.setContract(row);
    this.modal(ContractShowComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerContrat(row): void {
    this.contratService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  showMandat(item){
    this.mandatService.setMandate(item)
    this.mandatService.edit = false
    this.modal(MandateShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  editMandat(item){
    this.mandatService.setMandate(item)
    this.mandatService.edit = true
    this.modal(MandateAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  printMandat(item){
    this.mandatService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, null);
  }
  editBien(row) {
    this.bienService.setHouse(row)
    this.bienService.edit = true
    this.bienService.disponible = row.disponible
    this.modal(HouseAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  showBien(row) {
    this.bienService.setHouse(row)
    this.router.navigate(['/admin/proprietaire/bien/show/' + row.uuid]);
  }
  printerBien(row): void {
    this.bienService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  printerTicket(row): void {
    this.ticketService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  showConstruction(row) {
    this.constructionService.setConstruction(row);
    this.router.navigate(['/admin/intervention/show/' + row.uuid]);
  }
  printerConstruction(row): void {
    this.constructionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  editConstruction(row) {
    this.constructionService.setConstruction(row);
    this.constructionService.edit = true;
    this.constructionService.type = row.type;
    this.modal(ConstructionAddComponent, 'modal-basic-title', 'lg', true, 'static');
    this.modelRef.componentInstance.type = this.constructionService.type == "SYNDIC" ? "SYNDIC" : "LOCATIVE"
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  onClose(){
    this.modale.close('ferme');
  }
}
