<div class="modal-header">
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
  <!-- debut propriétaire-->
    <div class="col-md-12" *ngIf="entity === 'OWNER' && datas.length > 0">
        <div class="row">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions"
                    class="table table-sm table-striped table-bordered nowrap table-hover">
                    <thead class="thead-light">
                        <tr>
                          <th>Code</th>
                          <th>Type</th>
                          <th>Capital</th>
                          <th>Nom et Prenom</th>
                          <th>Contact</th>
                          <th>Agence</th>
                          <th>Nbre de Biens</th>
                          <th>Crée le</th>
                          <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of datas">
                          <td class="align-middle">{{item?.code}}</td>
                          <td class="align-middle">{{ item?.type }}</td>
                          <td class="align-middle">{{ item?.capital ? item?.capital : 'Non Définis' }}</td>
                          <td class="align-middle">{{ item?.nom }}</td>
                          <td class="align-middle">{{ item?.telephone }}</td>
                          <td class="align-middle">{{ item?.agency.nom }}</td>
                          <td class="align-middle">{{ item?.houses.length }}</td>
                          <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
                          </td>
                          <td class="table-action">
                              <button (click)="showOwner(item)" type="button" class="btn btn-icon btn-secondary"
                                  ngbTooltip="Détails">
                                  <i class="fas fa-eye"></i>
                              </button>
                              <button (click)="editOwner(item)" type="button" class="btn btn-icon btn-primary ml-1"
                                  ngbTooltip="Modifier">
                                  <i class="feather icon-edit-2"></i>
                              </button>
                              <button (click)="printerOwner(item)" type="button" class="btn btn-icon btn-warning ml-1"
                                  ngbTooltip="Imprimer">
                                  <i class="feather icon-printer"></i>
                              </button>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  <!-- debut propriétaire-->

  <!-- debut locataire-->
  <div class="col-md-12" *ngIf="entity === 'TENANT' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Type</th>
            <th>État</th>
            <th>Capital</th>
            <th>Impayé(s)</th>
            <th>Nom et Prenom</th>
            <th>Contact</th>
            <th>Agence</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">{{item?.code}}</td>
            <td class="align-middle">{{ item?.type }}</td>
            <td class="align-middle">{{ item?.etat }}</td>
            <td class="align-middle">{{ item?.capital ? item?.capital : 'Non Définis' }}</td>
            <td class="align-middle">{{ item?.impaye }}</td>
            <td class="align-middle">{{ item?.nom }}</td>
            <td class="align-middle">{{ item?.telephone }}</td>
            <td class="align-middle">{{ item?.agency.nom }}</td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
            </td>
            <td class="table-action">
              <button (click)="showTenant(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editTenant(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerTenant(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin locataire-->

  <!-- debut client-->
  <div class="col-md-12" *ngIf="entity === 'CUSTOMER' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Type</th>
            <th>NCC</th>
            <th>État</th>
            <th>Capital</th>
            <th>Impayé(s)</th>
            <th>Nom et Prenom</th>
            <th>Domicile</th>
            <th>Profession</th>
            <th>Contact</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">{{item?.code}}</td>
            <td class="align-middle">{{ item?.type }}</td>
            <td class="align-middle">{{ item?.ncc ? item?.ncc : 'Non Définis' }}</td>
            <td class="align-middle">{{ item?.etat }}</td>
            <td class="align-middle">{{ item?.capital ? item?.capital : 'Non Définis' }}</td>
            <td class="align-middle">{{ item?.impaye }}</td>
            <td class="align-middle">{{ item?.nom }}</td>
            <td class="align-middle">{{ item?.domicile ? item?.domicile : 'Non Définis' }}</td>
            <td class="align-middle">{{ item?.profession ? item?.profession : 'Non Définis' }}</td>
            <td class="align-middle">{{ item?.telephone ? item?.telephone : 'Non Définis' }}</td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
            </td>
            <td class="table-action">
              <button (click)="showClient(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editClient(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerClient(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin client-->

  <!-- debut locative-->
  <div class="col-md-12" *ngIf="entity === 'LOCATIVE' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Propriétaire</th>
            <th>Bien</th>
            <th>Locative</th>
            <th>Type</th>
            <th>État</th>
            <th>Étage</th>
            <th>Montant</th>
            <th>Occupant</th>
            <th>Pièce</th>
            <th>N° Porte</th>
            <th>Superficie</th>
            <th>Tantiemes</th>
            <th>Intervention</th>
            <th>Contrats</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">{{item?.code}}</td>
            <td class="align-middle">{{ item?.house.owner.nom }}</td>
            <td class="align-middle">{{item?.house.nom}}</td>
            <td class="align-middle">{{item?.libelle}}</td>
            <td class="align-middle">{{item?.type}}</td>
            <td class="align-middle">{{item?.etat}}</td>
            <td class="align-middle">{{ item?.etage ? item?.etage : 'Aucun' }}</td>
            <td class="align-middle">{{item?.montant}}</td>
            <td class="align-middle">{{item?.occupant}}</td>
            <td class="align-middle">{{item?.piece}}</td>
            <td class="align-middle">{{item?.porte}}</td>
            <td class="align-middle">{{item?.superficie}}</td>
            <td class="align-middle">{{item?.tantiemes.length ? item?.tantiemes.length : 'Aucun'}}</td>
            <td class="align-middle">{{item?.constructions.length ? item?.constructions.length : 'Aucune'}}</td>
            <td class="align-middle">{{item?.contracts.length ? item?.contracts.length : 'Aucun'}}</td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
            <td class="table-action">
              <button (click)="showRental(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editRental(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerRental(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin locative-->

  <!-- debut contrat-->
  <div class="col-md-12" *ngIf="entity === 'CONTRAT' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Locative</th>
            <th>Locataire</th>
            <th>Type</th>
            <th>Periode</th>
            <th>Etat</th>
            <th>Crée le</th>
            <th>Montant</th>
            <th>Payé</th>
            <th>Restant</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.rental?.libelle}}</span> <br /> Superficie :
                {{item?.rental?.superficie}} m² - {{item?.rental?.piece}} pièce(s): <br /> Propriétaire :
                {{item?.rental?.house?.owner?.searchableTitle}} <br /> Référence contrat : {{item?.code}}
              </p>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.tenant?.searchableTitle}}</span> <br /> Type :
                {{item?.tenant?.type}}<br /> Telephone : {{item?.tenant?.telephone}}
              </p>
            </td>
            <td>{{item?.type}}</td>
            <td>{{item?.periode}}</td>
            <td>
              <span class="badge" [ngClass]="{
                'badge-danger' : item?.etat === 'RESILIE',
                'badge-success' : item?.etat === 'ACTIF',
                'badge-warning' : item?.etat === 'INACTIF'
                }">
                {{item?.etat}}
              </span><br />
              <span class="ml-1 badge badge-light-danger" *ngIf="item?.etatLieux !=''">
                <small>{{ item?.etatLieux}}</small>
              </span><br />
              <span class="ml-1 badge badge-light-{{item?.signed !== null ? 'success' : 'danger'}}">
                <small>{{item?.signed !== null ? 'Contrat signé' : 'Contrat non signé'}}</small>
              </span>
            </td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.entranceInvoice?.montant|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-success">{{item?.entranceInvoice?.paye|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-danger">{{item?.entranceInvoice?.impaye|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showContrat(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                  <button (click)="editContrat(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                <button (click)="printerContrat(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin contrat-->

  <!-- debut mandat-->
  <div class="col-md-12" *ngIf="entity === 'MANDAT' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
          <thead class="thead-light">
          <tr>
            <th>Syndic</th>
            <th>Honoraires/Periodicité</th>
            <th>Date de signature</th>
            <th>Date</th>
            <th>Etat</th>
            <th>Crée le</th>
            <th class="text-center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td>
                    <span class="text-black">
                        <div class="d-flex align-items-center">
                        <div>
                            <span class="text-black d-block">
                                Code : {{item?.trustee?.code}}
                            </span>
                            <span class="text-black d-block">
                                Syndic : {{item?.trustee?.nom}}
                            </span>
                        </div>
                        </div>
                    </span>
            </td>
            <td>
                    <span class="text-black">
                    <div class="d-flex align-items-center">
                      <div>
                        <span class="text-black d-block">
                            Honoraires : {{item?.honoraires | number}}
                        </span>
                        <span class="text-black d-block">
                            Périodicité :
                            <span *ngIf="item?.periodicite === 'MENSUEL'">Mensuel</span>
                            <span *ngIf="item?.periodicite === 'TRIMESTRIEL'">Trimestriel</span>
                            <span *ngIf="item?.periodicite === 'ANNUEL'">Annuel</span>
                        </span>
                      </div>
                    </div>
                  </span>
            </td>
            <td>
                    <span class="text-black">
                        <div class="d-flex align-items-center">
                            <div>
                                <span class="text-black d-block">
                                    Signature : {{item?.date | date: "d MMMM y" : '' : 'fr-FR'}}
                                </span>
                            </div>
                        </div>
                    </span>
            </td>
            <td>
                    <span class="text-black">
                        <div class="d-flex align-items-center">
                            <div>
                                <span class="text-black d-block">
                                    Début : {{item?.dateD| date: "d MMMM y" : '' : 'fr-FR'}}
                                </span>
                                <span class="text-black d-block">
                                    Fin : {{item?.dateF| date: "d MMMM y" : '' : 'fr-FR'}}
                                </span>
                            </div>
                        </div>
                    </span>
            </td>
            <td>
              <span *ngIf="item?.etat === 'BROUILLON'" class="badge badge-info">Brouillon</span>
              <span *ngIf="item?.etat === 'VALIDE'" class="badge badge-success">Validé</span>
              <span *ngIf="item?.etat === 'RESILIE'" class="badge badge-danger">Résilié</span>
            </td>
            <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
            <td class="text-center">
              <button (click)="showMandat(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
                <button *ngIf="item?.etat !== 'VALIDE'" (click)="editMandat(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
              <button *ngIf="item?.etat !== 'VALIDE'" (click)="printMandat(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin mandat-->

  <!-- debut BIENS-->
  <div class="col-md-12" *ngIf="entity === 'BIENS' && datas.length > 0">
      <span class="badge badge-secondary mb-4 f-14 width">LISTE DES BIENS</span>
      <div class="row">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
            <thead class="thead-light">
            <tr>
              <th>code</th>
              <th>Nom</th>
              <th>Mandat</th>
              <th>Type</th>
              <th>Disponibilité</th>
              <th>Propriétaire</th>
              <th>Localisation</th>
              <th>Valeur</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of datas">
                <td class="align-middle">{{item?.code}}</td>
                <td class="align-middle">{{ item?.nom }}</td>
                <td class="align-middle">
                  <span class="badge badge-light-{{item?.isMandat ? 'warning' : 'danger'}} ml-1">
                    <small>{{item?.isMandat ? 'MANDAT EN COURS' : 'SANS MANDAT'}}</small> </span>
                </td>
                <td class="align-middle">
                  <span *ngIf="item?.disponible === 'VENTE'" class="badge" [ngClass]="{
                    'badge-success' : item?.etat === 'DISPONIBLE',
                    'badge-warning' : item?.etat === 'RESERVE',
                    'badge-danger' : item?.etat === 'VENDU'
                    }">
                    {{ item?.etat }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="badge badge-light-{{ item.disponible === 'LOCATION' ? 'info' : 'secondary' }}">
                    {{ item?.disponible === 'LOCATION' ? 'En location' : 'En vente' }}
                  </span>
                </td>
                <td class="align-middle">{{ item?.owner?.searchableTitle }}</td>
                <td class="align-middle">{{ item?.quartier + ' - ' + item?.commune+ ' - ' + item?.ville }}</td>
                <td class="align-middle">{{ !item.isMandat ? item?.valeur : item?.mandate?.valeur|number }} {{global.device}}</td>
                <td class="table-action">
                  <button (click)="showBien(item)" type="button" class="btn btn-icon btn-secondary"
                          ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button (click)="editBien(item)" type="button" class="btn btn-icon btn-primary ml-1"
                          ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                  <button (click)="printerBien(item)" type="button" class="btn btn-icon btn-warning ml-1"
                          ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

  </div>
  <!-- fin BIENS-->

  <!-- debut TICKET-->
  <div class="col-md-12" *ngIf="entity === 'TICKET' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <span class="badge badge-secondary mb-4 f-14 width">LISTE DES TICKETS</span>
        <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
          <thead class="thead-light">
            <tr>
              <th>Locataire</th>
              <th>Propriétaire</th>
              <th>Client</th>
              <th>Crée le</th>
              <th>État</th>
              <th>Objet</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of datas">
              <td class="align-middle">{{item.tenant?.nom}}</td>
              <td class="align-middle">{{item.owner?.nom}}</td>
              <td class="align-middle">{{item.customer?.nom}}</td>
              <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
              <td class="align-middle">
                <i [ngClass]="item.etat==='FERME'?'badge-danger':'badge-success'" class="badge  text-uppercase ml-2 f-12">
                  {{item.etat === 'FERME'? 'CLOTURÉ': 'OUVERT'}}
                </i>
              </td>
              <td class="align-middle">{{item?.objet}}</td>
              <td class="align-middle">{{item?.description}}</td>
              <td class="table-action">
                <button (click)="printerTicket(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin TICKET-->

  <!-- debut INTERVENTION-->
  <div class="col-md-12" *ngIf="entity === 'INTERVENTION' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <span class="badge badge-secondary mb-4 f-14 width">LISTE DES INTERVENTIONS</span>
        <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
          <thead class="thead-light">
          <tr>
            <th>Bien</th>
            <th>Libellé</th>
            <th>Evolution</th>
            <th>Etat</th>
            <th>Crée le</th>
            <th>Montant</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of datas">
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                                            <span class="text-primary">{{item?.rental ? item?.rental?.libelle :
                                              item?.house?.searchableTitle}}</span>
                <span class="text-primary">{{item?.trustee ? item?.trustee?.nom :
                  ''}}</span>
                <span *ngIf="item?.rental!=null || item?.house!=null">
                                                <br /> <span>
                                                    Superficie : {{item?.rental
                  ? item?.rental?.superficie : item?.house?.superficie}} m² -
                  {{item?.rental
                  ? item?.rental?.piece : item?.house?.nbrPiece}} pièce(s)
                                                </span>
                                            </span>
                <span *ngIf="item?.houseCo!=null">
                                                <br /> <span>Lot : {{item?.houseCo?.nom}}</span>
                                            </span>
                <span *ngIf="item?.homeCo!=null">
                                                <br /> <span>Villa ou appartement : {{item?.homeCo?.nom}}</span>
                                            </span>
                <span *ngIf="item?.infrastructure!=null">
                                                <br /> <span>Infrastructure : {{item?.infrastructure?.nom}}</span>
                                            </span>
                <span *ngIf="item?.nature!=null">
                                                <br /> <span>Nature : {{item?.nature?.libelle}}</span>
                                            </span>
                <span *ngIf="house!=null">
                                                <br /> <span>Propriétaire
                                                    : {{item?.house?.owner?.searchableTitle}}</span>
                                            </span>
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-primary">{{item?.nom}}</span><br /> Durée:
                {{timelapse(item?.dateD, item?.dateF)}} <br /> Début: {{item?.dateD|date: "d
                MMMM y" : '' : 'fr-FR'}} - Fin: {{item?.dateF|date: "d MMMM y" : '' :
                'fr-FR'}}
              </p>
            </td>
            <td>
              <circle-progress [percent]="item?.pourcentage" [radius]="25" [toFixed]="1"
               [titleFontSize]="10" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
               [outerStrokeColor]="item?.pourcentage >= 65 ? '#3FBD0D' : item?.pourcentage > 35 ? '#F0DD35' : '#EC0F0F'"
               [innerStrokeColor]="'#95cde4'" [animation]="true" [showSubtitle]="false"
               [animationDuration]="300">
              </circle-progress>
            </td>
            <td>
              <span class="badge" [ngClass]="{
                'badge-danger' : item?.etat === 'STOPPER',
                'badge-primary' : item?.etat === 'EN COURS',
                'badge-warning' : item?.etat === 'PREVU',
                'badge-success' : item?.etat === 'TERMINER'
                }"> {{ item?.etat === 'EN COURS' ? 'EN COURS DE REALISATION' : item?.etat }}
              </span>
            </td>
            <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.budget|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="table-action">
              <button (click)="showConstruction(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editConstruction(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerConstruction(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin INTERVENTION-->

  <!-- debut FOURNISSEUR-->
  <div class="col-md-12" *ngIf="entity === 'FOURNISSEUR' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Nom et Prenom</th>
            <th>Crée le</th>
            <th *ngIf="action">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">{{item?.code}}</td>
            <td class="align-middle">
              <img [src]="item?.photoSrc ? item?.photoSrc : 'assets/images/house-default.png'"
                   alt="{{item?.searchableTitle}}" class="rounded mr-2 ml-0" height="48" />
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.searchableTitle}}</span>
              </p>
            </td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
            </td>
            <td class="table-action">
              <button (click)="showRental(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editRental(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerRental(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin FOURNISSEUR-->

  <!-- debut INVOICE-->
  <div class="col-md-12" *ngIf="entity === 'INVOICE' && datas.length > 0">
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions"
               class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Nom et Prenom</th>
            <th>Crée le</th>
            <th *ngIf="action">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of datas">
            <td class="align-middle">{{item?.code}}</td>
            <td class="align-middle">
              <img [src]="item?.photoSrc ? item?.photoSrc : 'assets/images/house-default.png'"
                   alt="{{item?.searchableTitle}}" class="rounded mr-2 ml-0" height="48" />
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.searchableTitle}}</span>
              </p>
            </td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
            </td>
            <td class="table-action">
              <button (click)="showRental(item)" type="button" class="btn btn-icon btn-secondary"
                      ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button (click)="editRental(item)" type="button" class="btn btn-icon btn-primary ml-1"
                      ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="printerRental(item)" type="button" class="btn btn-icon btn-warning ml-1"
                      ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- fin INVOICE-->
</div>
