<div class="col-md-12">
    <span class="badge badge-secondary mb-4 f-14 width">LISTE DES FACTURES</span>
    <app-card [hidHeader]="true" cardClass="card-datatable">
        <div class="table-responsive">
            <table *ngIf="etat" datatable [dtOptions]="dtOptions"
                class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                    <tr>
                        <th *ngIf="prestataire">Fournisseur</th>
                        <th *ngIf="type  == 'SYNDIC'">Syndic</th>
                        <th *ngIf="type  == 'LOCATIVE'">Bien</th>
                        <th *ngIf="construction">Intervention</th>
                        <th>Libellé FACTURE</th>
                        <th>Date de transmission</th>
                        <th>Etat</th>
                        <th>Montant TTC</th>
                        <th>Montant Payé</th>
                        <th>Montant Restant</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoiceCos">
                        <td *ngIf="prestataire" class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">
                                <span class="text-primary">{{item?.provider?.nom}}</span><br />
                                Type: {{item?.provider?.type }} <br />
                                Telephone: {{item?.provider?.telephone}}
                            </p>
                        </td>
                        <td *ngIf="mode  == 'SYNDIC'">
                            <span class="text-primary">{{item?.trustee?.nom}}</span><br>
                            <span>
                                <span>Lot : {{item?.houseCo?.nom}} {{item?.homeCo?.nom}}</span><br />
                            </span>
                            <span *ngIf="item?.infrastructure!=null">
                                <span>Infrastructure : {{item?.infrastructure?.nom}}</span><br />
                            </span>
                        </td>
                        <td *ngIf="mode  == 'LOCATIVE'">
                            <span>
                                <span>Bien : {{item?.construction?.house?.searchableTitle}}</span><br />
                            </span>
                        </td>
                        <td *ngIf="construction && item?.trustee == null" class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">
                                Intervention : <span class="text-primary">{{item?.construction?.searchableTitle}}</span>
                                <br />
                                Bien : {{item?.construction?.rental ? item?.construction?.rental?.libelle :
                                item?.construction?.house?.searchableTitle}}
                            </p>
                        </td>
                        <td *ngIf="construction && item.trustee != null">
                            <span class="text-primary">{{item?.construction?.searchableTitle}}</span><br />
                        </td>
                        <td>
                            <span>{{ item?.libelle }}</span>
                        </td>
                        <td>{{ item.date|date: "d MMMM y hh:mm" : '' : 'fr-FR' }}</td>
                        <td>
                            <span class="badge" [ngClass]="{
                'badge-danger' : item?.etat === 'INVALIDE',
                'badge-success' : item?.etat === 'VALIDE'
                }">{{validation(item?.etat)}}</span>
                        </td>
                        <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">
                                <span class="text-success font-weight-blod">{{ item?.montant|number }} {{ global.device
                                    }}</span>
                            </p>
                        </td>
                        <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">
                                <span class="text-success font-weight-blod">{{ item?.montantPaye|number }} {{
                                    global.device
                                    }}</span>
                            </p>
                        </td>
                        <td class="align-middle">
                            <p class="m-0 d-inline-block align-middle font-16">
                                <span class="text-success font-weight-blod">{{ (item?.montant - item?.montantPaye)
                                    |number }} {{ global.device
                                    }}</span>
                            </p>
                        </td>
                        <td class="table-action">
                            <button *ngxPermissionsOnly="'INVOICE:CO:SHOW'" (click)="showInvoiceCo(item)" type="button"
                                class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                <i class="fas fa-eye"></i>
                            </button>
                            <ng-template ngxPermissionsOnly="INVOICE:CO:EDIT">
                                <button *ngIf="item?.etat === 'INVALIDE'" (click)="editInvoiceCo(item)" type="button"
                                    class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                    <i class="feather icon-edit-2"></i>
                                </button>
                            </ng-template>
                            <ng-template ngxPermissionsOnly="INVOICE:CO:VALIDATE">
                                <button *ngIf="item?.etat === 'INVALIDE'" (click)="validateInvoiceCo(item)"
                                    type="button" class="btn btn-icon btn-success ml-1" ngbTooltip="Valider">
                                    <i class="fas fa-check"></i>
                                </button>
                            </ng-template>
                            <button *ngIf="item?.etat === 'VALIDE'" (click)="printerInvoiceCo(item)" type="button"
                                class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                <i class="feather icon-printer"></i>
                            </button>
                            <button *ngxPermissionsOnly="'INVOICE:CO:DELETE'" type="button" (click)="delete(item)"
                                class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                <i class="feather icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td [attr.colspan]="prestataire && construction? '6' : '5'"
                            class="font-weight-bold font-size-40 text-right">TOTAL</td>
                        <td class="font-weight-bold font-size-40 text-warning"> {{ total | number }} {{global.device}}
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </app-card>
</div>